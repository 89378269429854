<template>
    <div>
        <div
            class="home page-wrapper position-relative overflow-y-hidden"
            v-if="dataLoaded"
        >
            <!-- video -->
            <section class="video-wrapper-section">
                <!-- mobile video -->
                <mq-layout
                    :mq="['xs', 'sm']"
                    class="embed-responsive embed-responsive-1by1"
                >
                    <video
                        :src="
                            require(`@/assets/video/${object.acf.section_1.video_mobile}.mp4`)
                        "
                        autoplay
                        loop
                        muted
                        preload="metadata"
                        playsinline
                        class="w-100 h-100 object-cover object-center"
                    ></video>
                </mq-layout>
                <!-- desktop video -->
                <mq-layout mq="md+" class="w-100 h-100">
                    <video
                        :src="
                            require(`@/assets/video/${object.acf.section_1.video_desktop}.mp4`)
                        "
                        autoplay
                        loop
                        muted
                        preload="metadata"
                        playsinline
                        class="w-100 h-100 object-cover object-center"
                    ></video>
                </mq-layout>
            </section>

            <div>
                <b-container class="container-longer" fluid>
                    <b-row class="no-gutters mt-4 mt-lg-5" v-in-viewport.once>
                        <b-col cols="12" class="fade-item">
                            <div class="section-text-wrapper text-center">
                                <h1>
                                    {{ object.acf.section_1.section_1_title }}
                                </h1>
                                <div
                                    v-html="
                                        object.acf.section_1
                                            .section_1_description
                                    "
                                    class="mb-3 text-bigger"
                                ></div>
                                <Button
                                    :url="object.acf.section_1.button.url"
                                    >{{
                                        object.acf.section_1.button.label
                                    }}</Button
                                >
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
            </div>

            <div class="mt-8 mb-6 mt-xl-6 mb-xl-7 position-relative">
                <ShapePicture class="shape-01" name="home/Shape-01" />
                <ShapePicture class="shape-02" name="home/Shape-02" />
                <TwoColumnsText
                    :title="object.acf.section_2.title"
                    :text="object.acf.section_2.description"
                >
                    <template #button>
                        <Button
                            :url="object.acf.section_2.button.url"
                            :bgColor="object.acf.section_2.button.color"
                            >{{ object.acf.section_2.button.label }}</Button
                        >
                    </template>
                </TwoColumnsText>
            </div>

            <div
                class="bg-secondary d-flex align-items-center position-relative py-4"
            >
                <div class="container-fluid text-center">
                    <h5
                        class="d-flex align-items-center justify-content-center mb-0 text-uppercase font-weight-bold"
                    >
                        Featured work
                    </h5>
                </div>
            </div>

            <transition name="fade">
                <div class="projects-list" v-if="filtered">
                    <Projects :projects="filteredProjects" :seeMore="true" />
                </div>
            </transition>

            <!-- services -->
            <section class="my-8 my-lg-7 position-relative" v-in-viewport.once>
                <!-- shape -->
                <ShapePicture class="shape-03" name="home/Shape-03" />
                <b-container class="container-longer fade-item" fluid>
                    <b-row class="no-gutters mt-4 mt-lg-5">
                        <b-col cols="12">
                            <div class="text-center">
                                <h2>{{ object.acf.services_title }}</h2>
                                <p class="text mt-lg-3">
                                    {{ object.acf.services_description }}
                                </p>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="no-gutters mt-2 mt-xl-5">
                        <span
                            class="col-6 col-md-4 col-xl-3 d-flex mt-2 mt-xl-3"
                            v-for="service in object.acf.services"
                            :key="service.slug"
                        >
                            <ServiceLink
                                :url="service.slug"
                                class="mx-1 mx-xl-2"
                                >{{ service.name }}</ServiceLink
                            >
                        </span>
                    </b-row>
                    <b-row class="mt-4">
                        <Button
                            class="mx-auto"
                            :url="object.acf.services_button.url"
                            :bgColor="object.acf.services_button.color"
                            >{{ object.acf.services_button.label }}</Button
                        >
                    </b-row>
                </b-container>
            </section>

            <!-- partners -->
            <section class="position-relative">
                <!-- shape -->
                <ShapePicture class="shape-04" name="home/Shape-04" />
                <Partners
                    :title="object.acf.partners_title"
                    :text="object.acf.partners_description"
                    :partners="object.acf.partners"
                    :button="object.acf.partners_button"
                >
                </Partners>
            </section>

            <div class="mb-lg-9">
                <ImageLeftRight
                    v-for="section in object.acf.below_work_content"
                    :key="section.title"
                    :title="section.title"
                    :text="section.description"
                    :image="section.image"
                    :imgPosition="section.image_position"
                    :button="section.button"
                    :buttonColor="section.button.color"
                    class="my-7 my-md-6"
                />
            </div>

            <!-- bottom page shape -->
            <ShapePicture class="bottom-page-shape" name="home/Shape-05" />
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import { mapGetters } from "vuex";
import seoMixin from "@/mixins/seoMixin";
import Button from "../components/Button.vue";
import TwoColumnsText from "../components/TwoColumnsText.vue";
import Projects from "../components/Projects.vue";
import ImageLeftRight from "../components/ImageLeftRight.vue";
import ServiceLink from "../components/ServiceLink.vue";
import Partners from "../components/Partners.vue";
import ShapePicture from "../components/ShapePicture.vue";
import Footer from "../components/Footer.vue";

export default {
    name: "home",
    mixins: [seoMixin],
    components: {
        Button,
        TwoColumnsText,
        Projects,
        ImageLeftRight,
        ServiceLink,
        Partners,
        ShapePicture,
        Footer
    },
    data() {
        return {
            object: {},
            dataLoaded: false,
            filtersOpened: false,
            filtered: true, //samo zbog fade animacije kad se klikne na neki filter
            activeID: 0 //id aktivnog filtera
        };
    },
    computed: {
        ...mapGetters(["navState", "mainCategories", "projects"]),

        // filtrirani projekti - mjenjaju se kad se promjeni activeID
        filteredProjects() {
            if (this.activeID === 0) {
                return this.projects.filter(project => {
                    // radimo sa projektima koji nemaju is_hidden property ili ga imaju ali je false
                    if (
                        !("is_hidden" in project.acf) ||
                        project.acf.is_hidden === false
                    ) {
                        // prikaži sve projekte koji imaju oznaju featured_project
                        return project.acf.featured_project === "yes";
                    }
                });
            }
            return this.projects.filter(project => {
                // radimo sa projektima koji nemaju is_hidden property ili ga imaju ali je false
                if (
                    !("is_hidden" in project.acf) ||
                    project.acf.is_hidden === false
                ) {
                    // prikaži sve projekte koji imaju oznaju featured_project i pripadaju nekoj kategoriji
                    return (
                        project.categories.includes(this.activeID) &&
                        project.acf.featured_project === "yes"
                    );
                }
            });
        }
    },
    watch: {
        $mq(newVal, oldVal) {
            // ako nije mobile -> prikaži filtere, ako je došlo do promjene viewport-a
            newVal !== "xs" && newVal !== "sm" && newVal !== "md"
                ? (this.filtersOpened = true)
                : (this.filtersOpened = false);
        }
    },
    methods: {
        goToPage(e, slug) {
            if ($("html").hasClass("touch")) {
                e.preventDefault();
                setTimeout(() => {
                    this.$router.push({
                        name: "work-detail",
                        params: { slug: slug }
                    });
                }, 1000); // čekamo prije prelaska na stranicu kratko vrijeme da se ispiše ime projekta na koji se kliknulo
            } else {
                this.$router.push({
                    name: "work-detail",
                    params: { slug: slug }
                });
            }
        },
        openFilters() {
            this.$mq === "xs" || this.$mq === "sm" || this.$mq === "md"
                ? (this.filtersOpened = true)
                : "";
        },
        applyFilters(id) {
            let vm = this;
            vm.filtered = false; //sakrij trenutne projekte
            vm.activeID = id;
            vm.closeFilters();
            setTimeout(() => {
                vm.filtered = true; //prikaži filtrirane projekte - fade animacija
            }, 400);
        },
        closeFilters() {
            this.$mq === "xs" || this.$mq === "sm" || this.$mq === "md"
                ? (this.filtersOpened = false)
                : "";
        },
        stickyCallback(obj) {
            if (obj.sticked) {
                document.querySelector(".sticky-nav").classList.add("fixed");
            } else {
                document.querySelector(".sticky-nav").classList.remove("fixed");
                document
                    .querySelector(".sticky-nav")
                    .classList.add("position-relative");
            }
        }
    },
    created() {
        const pageApi = "wp-json/wp/v2/pages/19?_fields=id,slug,parent,acf";
        const data = axios.get(pageApi).then(res => {
            this.object = res.data;
            // from seoMixin
            this.setSeo(
                this.object.acf.seo_title,
                this.object.acf.seo_description,
                "https://www.wearealive.com/WAA_default.jpeg",
                "https://www.wearealive.com" + this.$route.fullPath
            );
            this.dataLoaded = true;
            document.dispatchEvent(new Event("custom-render-trigger"));

            setTimeout(() => {
                $("body").css("visibility", "unset");
                this.$store.dispatch("setLoading", false);
            }, loadingDelay);
        });

        // na 1. load da li da prikaže filtere ili ne
        this.$mq === "xs" || this.$mq === "sm" || this.$mq === "md"
            ? (this.filtersOpened = false)
            : (this.filtersOpened = true);
    }
};
</script>
