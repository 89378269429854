<template>
    <router-link :to="`/services/${url}`" class="service-link"
        ><slot></slot
    ></router-link>
</template>

<script>
export default {
    props: {
        url: {
            type: String,
            default: "/"
        }
    }
};
</script>

<style lang="scss" scoped></style>
